<template>
  <div class="login">
    <img src="../assets/img/bg_logon.png" draggable="false" class="posAbsoluteAll objFitCover"/>
    <img src="../assets/img/logo2.png" draggable="false" class="img-logo2"/>
    <div class="center-div column-me center-all">
      <img src="../assets/img/logo.png" class="img-logo" draggable="false">
      <img src="../assets/img/title.png" class="img-title" draggable="false">
      <div class="card-div column-me row-center">
        <div class="item-input row-me row-center">
          <img src="../assets/img/icon_sjh.png" class="img-icon mL14">
          <div class="mL10 flex1">
            <el-input ref="account" class="input-div" placeholder="请输入账号" clearable v-model="account"
                      :class="{'red-border':showRedBorder&&account===''}" @keyup.enter="clickLogin"></el-input>
          </div>
        </div>
        <div class="item-input row-me row-center mT20">
          <img src="../assets/img/icon_mima.png" class="img-icon mL20">
          <div class="mL10 flex1">
            <el-input ref="password" class="input-div" placeholder="请输入密码" type="password" show-password clearable
                      v-model="password" :class="{'red-border':showRedBorder&&password===''}" @keyup.enter="clickLogin">
            </el-input>
          </div>
        </div>
        <div class="w380 row-me row-center mT20">
          <div class="item-input row-me row-center flex1">
            <img src="../assets/img/icon_yzm.png" class="img-icon mL20">
            <div class="mL10 flex1">
              <el-input ref="code" class="input-div" placeholder="请输入验证码" clearable v-model="code"
                        :class="{'red-border':showRedBorder&&(code===''||identifyCode!==code)}"
                        @keyup.enter="clickLogin"></el-input>
            </div>
          </div>
          <div class="login-code" @click="refreshCode">
            <!--验证码组件-->
            <s-identify :identifyCode="identifyCode" :fontSizeMin="40" :fontSizeMax="40"></s-identify>
          </div>
        </div>
        <div class="btn-blue btn-login" @click="clickLogin">登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import SIdentify from '../components/sidentify/sidentify.vue'

export default {
  components: {
    SIdentify
  },
  data() {
    return {
      account: '',
      password: '',
      code: '',
      identifyCodes: "1234567890abcdefghijklmnopqrstuvwxyz",
      identifyCode: "",
      showRedBorder: false,
    }
  },
  // html加载完成之前
  created() {
    this.refreshCode()
  },
  // html加载完成后执行。
  mounted() {
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
  },
  // 事件方法执行
  methods: {
    //验证码
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
            this.randomNum(0, this.identifyCodes.length)
            ];
      }
    },
    clickLogin() {
      if (this.account === '' || this.password === '' || this.code === '') {
        this.showRedBorder = true
        if (this.account === '') {
          this.$refs.account.focus()
        } else if (this.password === '') {
          this.$refs.password.focus()
        } else if (this.code === '') {
          this.$refs.code.focus()
        }
        return
      }
      if (this.identifyCode !== this.code) {
        this.showRedBorder = true
        this.$message.error('验证码错误')
        this.$refs.code.focus()
        return
      }
      sessionStorage.removeItem('clickMenuUrl1')
      sessionStorage.removeItem('clickMenuUrl2')
      this.$post('/login', {
        username: this.account,
        password: this.password,
      }).then((res) => {
        if (res) {
          sessionStorage.setItem('token', res.data.token)
          this.$message.success('登录成功')
          this.$router.replace({
            path: '/'
          })
        }
      }).catch((error) => {
        console.log(error)
      })
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
}
</script>

<style lang="scss" scoped>
.login {
  width: 100vw;
  height: 100vh;
  position: relative;

  .img-logo2 {
    width: 90px;
    height: 66px;
    position: absolute;
    left: 120px;
    top: 78px;
  }

  .center-div {
    position: absolute;
    top: 50%;
    right: 260px;
    transform: translateY(-50%);
    width: 500px;
    height: 660px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(17, 130, 251, 0.05);
    border-radius: 20px;

    .img-logo {
      width: 168px;
      height: 95px;
      object-fit: scale-down;
    }

    .img-title {
      width: 400px;
      height: 36px;
      object-fit: scale-down;
      margin-top: 20px;
    }

    .img-title1 {
      width: 220px;
      height: 36px;
      object-fit: scale-down;
      margin-top: 20px;
    }

    .card-div {
      width: 380px;
      margin-top: 40px;

      .img-icon {
        width: 22px;
        height: 22px;
        object-fit: scale-down;
      }

      .item-input {
        width: 380px;
        height: 60px;
        background: #F8F8F8;
        border-radius: 4px;

        .input-div {
          &:deep(.el-input__inner) {
            font-size: 18px;
            background: none;
            border: none;
            outline: none;
          }

          &:deep(.el-input__wrapper) {
            box-shadow: none;
            background: none;
          }

          &:deep(.el-input__inner::-webkit-input-placeholder) {
            font-size: 18px;
            color: #999999;
          }
        }
      }

      .login-code {
        cursor: pointer;
      }

      .btn-login {
        width: 380px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        letter-spacing: 5px;
        font-size: 20px;
        font-weight: 500;
        color: #FFFFFF;
        margin-top: 40px;
      }
    }
  }
}
</style>
